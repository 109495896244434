import store from "@/state/store";
import { MenuPerms } from "../enums/menu-perms";
import { userService } from "../services/user.service";

export default [
  {
    path: "/event/:id",
    name: "eventDetail",
    meta: { title: "Event detail", tkey: "event-detail-page-title", authRequired: true },
    component: () => import("../views/events-log/detail"),
  },
  {
    path: "/customers",
    name: "Customer",
    meta: { title: "Customers", menuPerm: MenuPerms.CustomerCardAndContactPersons, tkey: "customer-list-page-title", authRequired: true },
    component: () => import("../views/customers/list"),
  },
  {
    path: '/customer/add',
    name: 'customerAdd',
    meta: { title: "Add customer", menuPerm: MenuPerms.CustomerCardAndContactPersons, checkAllowEdit: true, tkey: "customer-add-page-title", authRequired: true },
    component: () => import("../views/customers/edit.vue")
  },
  {
    path: "/customer/edit/:custNr",
    name: "customerEdit",
    meta: { title: "Edit Customer", menuPerm: MenuPerms.CustomerCardAndContactPersons, checkAllowEdit: true, tkey: "customer-edit-page-title", authRequired: true },
    component: () => import("@/views/customers/edit.vue"),
  },
  {
    path: "/customer/:custNr",
    name: "customerDetail",
    meta: { title: "Customer detail", tkey: "customer-detail-page-title", authRequired: true },
    component: () => import("../views/customers/detail/index.vue"),
  },
  {
    path: "/projects",
    name: "projects",
    meta: { title: "Projects", tkey: 'project-list-page-title', authRequired: true },
    component: () => import("../views/projects/list.vue")
  },
  {
    path: '/project/edit/:id',
    name: 'projectEdit',
    meta: { title: "Edit project", tkey: 'project-edit-page-title', authRequired: true },
    component: () => import("../views/projects/edit.vue")
  },
  {
    path: '/project/add',
    name: 'projectAdd',
    meta: { title: "Add project", tkey: 'project-add-page-title', authRequired: true },
    component: () => import("../views/projects/edit.vue")
  },
  {
    path: '/project/:id',
    name: 'projectDetail',
    meta: { title: "Detail project", tkey: 'project-detail-page-title', authRequired: true },
    component: () => import("../views/projects/detail/index.vue")
  },
  {
    path: '/project/:id/gantt/dark',
    name: 'projectGanttDark',
    meta: { title: "Project gantt chart", authRequired: true },
    component: () => import("../views/projects/task/gantt-dark.vue")
  },
  {
    path: '/project/:id/gantt/light',
    name: 'projectGanttLight',
    meta: { title: "Project gantt chart", authRequired: true },
    component: () => import("../views/projects/task/gantt-light.vue")
  },
  {
    path: '/tickets/pt',
    name: 'ptTickets',
    meta: { title: "PT tickets", tkey: 'ticket-list-pt-title', authRequired: true },
    component: () => import("../views/tickets/list/index.vue")
  },
  {
    path: '/tickets/service',
    name: 'serviceTickets',
    meta: { title: "Service tickets", tkey: 'ticket-list-service-title', authRequired: true },
    component: () => import("../views/tickets/list/index.vue")
  },
  {
    path: '/service/products',
    name: 'serviceProduct',
    meta: { title: "Service products", tkey: 'ticket-service-product-title', authRequired: true },
    component: () => import("../views/tickets/service-product/list.vue")
  },
  {
    path: '/service/product/add',
    name: 'addServiceProduct',
    meta: { title: "Add service product", tkey: 'ticket-service-product-add-title', authRequired: true },
    component: () => import("../views/tickets/service-product/edit.vue")
  },
  {
    path: '/service/product/edit/:id',
    name: 'editServiceProduct',
    meta: { title: "Edit service product", tkey: 'ticket-service-product-edit-title', authRequired: true },
    component: () => import("../views/tickets/service-product/edit.vue")
  },
  {
    path: '/tickets/et',
    name: 'etTickets',
    meta: { title: "Inbox folder", tkey: 'ticket-list-et-title', authRequired: true },
    component: () => import("../views/tickets/list/index.vue")
  },
  {
    path: '/tickets/sent',
    name: 'sentTickets',
    meta: { title: "Sent folder", tkey: 'simple-ticket-list-title', authRequired: true },
    component: () => import("../views/tickets/email/list-sent.vue")
  },
  {
    path: '/tickets/trash',
    name: 'trashTickets',
    meta: { title: "Trash folder", tkey: 'ticket-list-trash-title', authRequired: true },
    component: () => import("../views/tickets/list/index.vue")
  },
  {
    path: '/tickets/spam',
    name: 'spamTickets',
    meta: { title: "Spam folder", tkey: 'ticket-list-spam-title', authRequired: true },
    component: () => import("../views/tickets/list/index.vue")
  },
  {
    path: "/ticket/:id",
    name: "ticketDetail",
    meta: { title: "Ticket info", authRequired: true },
    component: () => import("../views/tickets/detail/index.vue"),
  },
  {
    path: "/ticket/print/:id",
    name: "printTicket",
    meta: { title: "Print ticket", authRequired: true },
    component: () => import("../views/tickets/print/print-ticket.vue"),
  },
  {
    path: "/share/:id",
    name: "shareTicket",
    meta: { title: "Share ticket", authRequired: false },
    component: () => import("../views/tickets/share/share-ticket.vue"),
  },
  {
    path: "/ticket/add/:type",
    name: "ticketAdd",
    meta: { title: "New ticket", tkey: 'ticket-add-page-title', authRequired: true },
    component: () => import("../views/tickets/edit/edit.vue"),
  },
  {
    path: "/ticket/send-email",
    name: "sendEmail",
    meta: { title: "Send email", tkey: 'simple-ticket-page-title', authRequired: true },
    component: () => import("../views/tickets/email/send-email.vue"),
  },
  {
    path: "/ticket/edit/:id",
    name: "ticketEdit",
    meta: { title: "Edit ticket", tkey: 'ticket-edit-page-title', authRequired: true },
    component: () => import("../views/tickets/edit/edit.vue"),
  },
  {
    path: '/time-tracking',
    name: 'timeTracking',
    meta: { title: "Time tracking", tkey: 'time-tracking-page-title', authRequired: true },
    component: () => import("../views/time-tracking/index.vue")
  },
  {
    path: '/time-tracking/add',
    name: 'addTimeTracking',
    meta: { title: "Add time tracking", tkey: 'time-tracking-page-add-title', authRequired: true },
    component: () => import("../views/time-tracking/add.vue")
  },
  {
    path: '/time-tracking/edit/:id',
    name: 'editTimeTracking',
    meta: { title: "Edit time tracking", tkey: 'time-tracking-page-edit-title', authRequired: true },
    component: () => import("../views/time-tracking/edit.vue")
  },
  {
    path: "/absence-handling",
    name: "absenceListAdmin",
    meta: { title: "Absence handling", authRequired: true },
    component: () => import("../views/absence/list-admin.vue"),
  },
  {
    path: "/absence",
    name: "absenceListUser",
    meta: { title: "Absence", authRequired: true },
    component: () => import("../views/absence/list-user.vue"),
  },
  {
    path: "/absence-handling/:id",
    name: "absenceHandlingDetail",
    meta: { title: "Absence handling detail", tkey: "absence-detail-text", authRequired: true },
    component: () => import("../views/absence/detail.vue"),
  },
  {
    path: "/absence/:id",
    name: "absenceDetail",
    meta: { title: "Absence detail", tkey: "absence-detail-text", authRequired: true },
    component: () => import("../views/absence/detail.vue"),
  },
  {
    path: "/absence/add",
    name: "absenceAdd",
    meta: { title: "Add absence", tkey: "absence-add-text", authRequired: true },
    component: () => import("../views/absence/edit.vue"),
  },
  {
    path: "/absence/edit/:id",
    name: "absenceEdit",
    meta: { title: "Edit absence ", authRequired: true },
    component: () => import("../views/absence/edit.vue"),
  },
  {
    path: "/faqs",
    name: "faqs",
    meta: { title: "FAQs", tkey: 'faq-page-title', authRequired: true },
    component: () => import("../views/faq/list.vue"),
  },
  {
    path: "/todo-list",
    name: "todoList",
    meta: { title: "Todo list", tkey: 'todo-page-title', authRequired: true },
    component: () => import("../views/todo/list.vue"),
  },
  {
    path: "/calendar",
    name: "eventCalendar",
    meta: { title: "Event Calendar", tkey: 'calendar-page-title', authRequired: true },
    component: () => import("../views/calendar/index.vue"),
  },
  {
    path: "/sales-leads",
    name: "salesLeads",
    meta: { title: "Sales Leads", authRequired: true },
    component: () => import("../views/sales-leads/index.vue"),
  },
  {
    path: "/digital-signing",
    name: "digitalSigning",
    meta: { title: "Digital signing", authRequired: true },
    component: () => import("../views/digital-signing/index.vue"),
  },
  {
    path: "/digital-signing/add",
    name: "newSigningRequest",
    meta: { title: "New signing request", authRequired: true },
    component: () => import("../views/digital-signing/add.vue"),
  },

  { path: '/settings', redirect: { name: 'generalSettings' } },
  {
    path: "/settings/categories",
    name: "Categories",
    meta: { title: "Categories", tkey: 'category-page-title', authRequired: true },
    component: () => import("../views/settings/categories/list.vue"),
  },
  {
    path: "/settings/ticket-status",
    name: "ticketStatus",
    meta: { title: "Ticket status", tkey: 'ticket-status-page-title', authRequired: true },
    component: () => import("../views/settings/ticket/ticket-status/list.vue"),
  },
  {
    path: "/settings/ticket-type",
    name: "ticketType",
    meta: { title: "Ticket type", tkey: 'ticket-type-page-title', authRequired: true },
    component: () => import("../views/settings/ticket/ticket-type/list.vue"),
  },
  {
    path: "/settings/ticket-priority",
    name: "ticketPriority",
    meta: { title: "Ticket priority", tkey: 'ticket-priority-page-title', authRequired: true },
    component: () => import("../views/settings/ticket/ticket-priority/list.vue"),
  },
  {
    path: "/settings/ticket-resolution",
    name: "ticketResolution",
    meta: { title: "Ticket resolution", tkey: 'ticket-resolution-page-title', authRequired: true },
    component: () => import("../views/settings/ticket/ticket-resolution/list.vue"),
  },
  {
    path: "/settings/roles",
    name: "roles",
    meta: { title: "Roles", tkey: 'role-page-title', authRequired: true },
    component: () => import("../views/settings/user-role/roles/list.vue"),
  },
  {
    path: "/settings/role/add",
    name: "roleAdd",
    meta: { title: "Add role", authRequired: true },
    component: () => import("../views/settings/user-role/roles/edit.vue"),
  },
  {
    path: "/settings/role/edit/:id",
    name: "roleEdit",
    meta: { title: "Edit role", authRequired: true },
    component: () => import("../views/settings/user-role/roles/edit.vue"),
  },
  {
    path: "/settings/roles-management",
    name: "rolesManagement",
    meta: { title: "Roles management", tkey: 'role-management-page-title', authRequired: true },
    component: () => import("../views/settings/user-role/roles-management/index.vue"),
  },
  {
    path: "/settings/widgets-management",
    name: "widgetsManagement",
    meta: { title: "Widgets management", tkey: 'widget-management-page-title', authRequired: true },
    component: () => import("../views/settings/user-role/widgets-management/index.vue"),
  },
  {
    path: "/settings/users",
    name: "users",
    meta: { title: "Users", tkey: "user-list-page-title", authRequired: true },
    component: () => import("../views/settings/user-role/users/list.vue"),
  },
  {
    path: "/settings/user/edit/:id",
    name: "editUser",
    meta: { title: "Edit user", tkey: "user-edit-page-title", authRequired: true },
    component: () => import("../views/settings/user-role/users/edit.vue"),
  },
  {
    path: "/user/activate/:token",
    name: "userActivate",
    meta: { title: "User activate", authRequired: false },
    component: () => import("../views/account/activate-admin-account.vue"),
  },
  {
    path: "/user/invite/:token",
    name: "userAcceptInvitation",
    meta: { title: "User accept invitation", authRequired: false },
    component: () => import("../views/account/user-accept-invite.vue"),
  },
  {
    path: "/signicat/:status",
    name: "signicatCallback",
    meta: { title: "Signicat callback", authRequired: false },
    component: () => import("../views/digital-signing/signicat-callback.vue"),
  },
  {
    path: "/ga-chart",
    name: "gaChart",
    meta: { title: "Google analytics Chart", authRequired: true },
    component: () => import("../views/google-analytics/index.vue"),
  },
  {
    path: "/oauth2",
    name: "gaOauth2Callback",
    meta: { title: "Oauth2 callback", authRequired: true },
    component: () => import("../views/google-analytics/oauth2-callback.vue"),
  },
  {
    path: "/user/reset-pwd/:token",
    name: "userResetPassword",
    meta: { title: "Reset password", authRequired: false },
    component: () => import("../views/account/reset-password.vue"),
  },
  {
    path: "/settings/general-settings",
    name: "generalSettings",
    meta: { title: "General settings", tkey: 'general-setting-title', authRequired: true },
    component: () => import("../views/settings/general-settings/index.vue"),
  },
  {
    path: "/settings/imex/import-export-customer",
    name: "importExportCustomer",
    meta: { title: "Import/Export customer", tkey: 'setting-imex-customer-title', authRequired: true },
    component: () => import("../views/settings/import-export-customer/index.vue"),
  },
  {
    path: "/settings/imex/cshop-customer-import-settings",
    name: "cshopImportCustomerSettings",
    meta: { title: "Cshop customer import settings", tkey: 'menu-cshop-customer-import-setting', authRequired: true },
    component: () => import("../views/settings/cshop-customer-import-settings/index.vue"),
  },
  {
    path: "/settings/accounting-settings",
    name: "accountingSettings",
    meta: { title: "Accounting settings", tkey: 'accounting-setting-title', authRequired: true },
    component: () => import("../views/settings/accounting-settings/index.vue"),
  },
  {
    path: "/settings/ticket/default-settings",
    name: "DefaultTicketSettings",
    meta: { title: "Default ticket settings", tkey: 'ticket-setting-page-title', authRequired: true },
    component: () => import("../views/settings/hidden-settings/index.vue"),
  },
  {
    path: "/settings/hidden",
    name: "hiddenSettings",
    meta: { title: "Hidden settings", authRequired: true },
    component: () => import("../views/settings/hidden-settings/index.vue"),
  },
  {
    path: "/settings/email-template-settings",
    name: "emailTemplateSettings",
    meta: { title: "Email template settings", tkey: "email-template-setting-page-title", authRequired: true },
    component: () => import("../views/settings/email-template-settings/index.vue"),
  },
  {
    path: "/settings/email-queues",
    name: "emailQueues",
    meta: { title: "Email queues", tkey: "email-queue-page-title", authRequired: true },
    component: () => import("../views/settings/email-queues/index.vue"),
  },
  {
    path: "/settings/email-ticket-settings",
    name: "emailTicketSettings",
    meta: { title: "Email ticket settings", tkey: "email-ticket-page-title", authRequired: true },
    component: () => import("../views/settings/email-ticket-settings/index.vue"),
  },
  {
    path: "/settings/digital-signing-settings",
    name: "digitalSigningSettings",
    meta: { title: "digitalSigningSettings", tkey: "digital-signing-page-title", authRequired: true },
    component: () => import("../views/settings/digital-signing-settings/signicat.vue"),
  },
  {
    path: "/settings/accounting-invoicing",
    name: "accountingInvoicing",
    meta: { title: "Accounting invoicing", tkey: "accounting-invoicing-page-title", authRequired: true },
    component: () => import("../views/settings/accounting-invoicing/invoicing/index.vue"),
  },
  {
    path: "/settings/pending-confirm",
    name: "pendingConfirm",
    meta: { title: "Pending confirmation", tkey: 'pogo-pending-page-title', authRequired: true },
    component: () => import("../views/settings/accounting-invoicing/pending-confirm/index.vue"),
  },
  {
    path: "/settings/absence-reason",
    name: "absenceReason",
    meta: { title: "Absence reason", tkey: 'pogo-invoicing-page-title', authRequired: true },
    component: () => import("../views/settings/absence-reason/list.vue"),
  },
  {
    path: "/settings/google-analytics",
    name: "googleAnalytics",
    meta: { title: "Google analytics setting", tkey: 'ga-setting-page-title', authRequired: true },
    component: () => import("../views/settings/google-analytics/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/auto-login',
    name: 'autoLogin',
    meta: {
      title: "Auto login", authRequired: false,
    },
    component: () => import('../views/account/auto-login')
  },
  {
    path: '/init-settings',
    name: 'initSettings',
    meta: {
      title: "Init settings", tkey: 'account-init-settings-page-title', authRequired: true,
    },
    component: () => import('../views/account/init-settings')
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        userService.logout();
        next();
      },
    },
    component: () => import("../views/auth/logout")
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      title: "Reset password", authRequired: true,
    },
    component: () => import('../views/account/reset-password')
  },
  {
    path: '/auth/lockscreen',
    name: 'lockscreen',
    meta: {
      title: "Lock Screen", authRequired: false,
    },
    component: () => import('../views/auth/lockscreen')
  },
  {
    path: '/error/500',
    name: 'error-500',
    meta: {
      title: "Error 500 - Interal Error", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/error/404',
    name: 'eror-404',
    meta: {
      title: "Error 404 - Not Found", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/error/403',
    name: 'error-403',
    meta: {
      title: "Error 403 - Access denied", authRequired: true,
    },
    component: () => import('../views/auth/errors/403')
  },
  {
    path: '/auth/offline',
    name: 'offline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/offline')
  },
  {
    path: '/msg/success',
    name: 'success-msg',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg')
  },
  {
    path: "/staff/profile",
    name: "staffProfile",
    meta: { title: "View profile", tkey: 'profile-text-view-profile', authRequired: true },
    component: () => import("../views/staff/profile/index.vue"),
  },
  {
    path: "/staff/edit-profile",
    name: "staffEditProfile",
    meta: { title: "Edit profile", tkey: 'profile-text-edit-profile', authRequired: true },
    component: () => import("../views/staff/edit-profile/index.vue"),
  },
  {
    path: "/staff/settings",
    name: "staffSettings",
    meta: { title: "Settings", tkey: 'g-settings', authRequired: true },
    component: () => import("../views/staff/settings/index.vue"),
  },
  {
    path: "/staff/config-2fa",
    name: "config-2FA",
    meta: { title: "Config 2FA", tkey: 'profile-page-title-config-2fa', authRequired: true },
    component: () => import("../views/staff/config-2fa/index.vue"),
  },
  {
    path: "/marketplaces",
    name: "marketplaces",
    meta: { title: "Marketplaces", tkey: 'market-page-title', authRequired: true },
    component: () => import("../views/marketplaces/products/list"),
  },
  {
    path: "/market/:code/:id",
    name: "marketProductInfo",
    meta: { title: "Market product info", tkey: 'market-info-page-title', authRequired: true },
    component: () => import("../views/marketplaces/products/detail"),
  },
  {
    path: "/checkout",
    name: "marketCheckout",
    meta: { title: "Checkout", tkey: 'market-checkout-page-title', authRequired: true },
    component: () => import("../views/marketplaces/products/checkout"),
    // props: true
  },
  {
    path: "/market/subscriptions",
    name: "subscriptionList",
    meta: { title: "Subscriptions", tkey: 'market-subscr-page-title', authRequired: true },
    component: () => import("../views/marketplaces/subscriptions/list"),
  },
  {
    path: "/market/subscription/:id",
    name: "subscrInfo",
    meta: { title: "Subscription info", tkey: 'market-subscr-info-page-title', authRequired: true },
    component: () => import("../views/marketplaces/subscriptions/detail"),
  },
  {
    path: "/api/api-keys",
    name: "apiKeys",
    meta: { title: "API Keys", authRequired: true },
    component: () => import("../views/api/api-keys/index"),
  },
  {
    path: "/api/api-doc",
    name: "apidoc",
    meta: { title: "API document", authRequired: true },
    component: () => import("../views/api/api-doc/index"),
  },
  {
    path: "/terms-of-service",
    name: "termsOfService",
    meta: { title: "Terms of service", tkey: 'g-terms-of-service', authRequired: false },
    component: () => import("../views/footer-links/terms-of-service.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    meta: { title: "Privacy Policy", tkey: 'g-privacy-policy', authRequired: false },
    component: () => import("../views/footer-links/privacy-policy.vue"),
  },
  {
    path: "/contact-us",
    name: "contactUs",
    meta: { title: "Contact us", tkey: 'g-contact-us', authRequired: false },
    component: () => import("../views/footer-links/contact-us.vue"),
  },
  {
    path: "/icons/remix",
    name: "iconsRemix",
    meta: { title: "Remix icons", authRequired: true },
    component: () => import("../views/icons/remix.vue"),
  },
  {
    path: "/:type/attach/:dataId/:fileName",
    name: "viewAttachFile",
    meta: { title: "View ticket/comment/email attach", authRequired: true },
    component: () => import("../views/file/ticket-attach.vue"),
  },
  {
    path: "/email/attach/:fileName",
    name: "viewEmailAttachFile",
    meta: { title: "View ticket/comment/email attach", authRequired: true },
    component: () => import("../views/file/ticket-attach.vue"),
  },
  {
    path: "/reset-2fa-app",
    name: "reset-2fa-app",
    component: () => import("../views/account/reset-2fa-app.vue"),
    meta: {
      title: "Reset 2FA app",
      authRequired: false
    }
  },
  {
    path: "/reinstall-2fa-app",
    name: "reinstall-2fa-app",
    component: () => import("../views/account/reinstall-2fa-app.vue"),
    meta: {
      title: "Reinstall 2FA app",
      authRequired: false
    }
  },
];